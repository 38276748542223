import React from "react";
import styled from "styled-components";
import Title from "../../UI/Title/Title";

const StyledDiv = styled.div`
    &.Wrapper {
        grid-column: 1/3;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: column;
        border-bottom: 1px solid #ccc;
    }
    &.Item {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 1.25rem 0;
        font-size: 1.5rem;

        &:hover {
            background-color: #f2f2f2;
        }

        &.active {
            background-color: #f2f2f2;
        }
    }
`;

const StyledH2 = styled.h2`
    &.Title {
        font-size: 1rem;
        letter-spacing: normal;
        font-weight: 700;
        margin-bottom: 0;
    }
`;

const Tabs = ({ options, active, onClick }) => {
    return (
        <StyledDiv className="Wrapper">
            {options.map((e, index) => {
                return (
                    <StyledDiv onClick={() => onClick(e)} key={index} className={`Item ${active === e ? "active" : ""}`}>
                        <StyledH2 className={`Title`}>{e}</StyledH2>
                    </StyledDiv>
                );
            })}
        </StyledDiv>
    );
};

export default Tabs;
