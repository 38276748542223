import React from "react"
import {Carousel} from "./Carousel";
import Title from "../Title";
import bs1 from "../../assets/fleet/businessClass/32884842_10216644889103552_1438302419065765888_n.jpg"
import bs2 from "../../assets/fleet/businessClass/32948651_10216644889703567_7272828238697594880_n.jpg"
import bs3 from "../../assets/fleet/businessClass/32965902_10216644888823545_3789507519945113600_n.jpg"

import es2 from "../../assets/fleet/executiveSedan/S-Class 4.jpg"
import es3 from "../../assets/fleet/executiveSedan/18624801_10213365279275356_183963331_n.jpg"

import eSuv1 from "../../assets/fleet/executiveSuv/GLE Inside.jpg"
import eSuv2 from "../../assets/fleet/executiveSuv/GLE Inside Passenger.jpg"
import eSuv3 from "../../assets/fleet/executiveSuv/Gle Limousine.jpg"
import eSuv4 from "../../assets/fleet/executiveSuv/Mercedes GLE Limo.jpg"

import eVab1 from "../../assets/fleet/exevutiveVan/36542646_10216982573225444_705191685352587264_n.jpg"
import eVab2 from "../../assets/fleet/exevutiveVan/V-Class Extra long.jpg"
import eVab4 from "../../assets/fleet/exevutiveVan/V-Class limo.jpg"
import eVab5 from "../../assets/fleet/exevutiveVan/V-Class 2018.jpg"


const CarouselList = ({ fleets }) => {

    // const images = [
    //     "https://images.unsplash.com/photo-1588392382834-a891154bca4d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmF0dXJlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    //     "https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8bmF0dXJlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    //     "https://images.unsplash.com/photo-1470770903676-69b98201ea1c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fG5hdHVyZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    //     "https://images.unsplash.com/photo-1510797215324-95aa89f43c33?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzR8fG5hdHVyZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    //     "https://images.unsplash.com/photo-1536431311719-398b6704d4cc?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fG5hdHVyZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    // ];
    // const images2 = [
    //     "https://images.unsplash.com/photo-1611095564350-2cbe940a8d99?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1502&q=80",
    //     "https://images.unsplash.com/photo-1621185368994-df77dfb52996?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    //     "https://images.unsplash.com/photo-1621184976252-4a8c472ccec4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=888&q=80",
    //     "https://images.unsplash.com/photo-1621181222575-ea09dc3be3ab?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
    //     "https://images.unsplash.com/photo-1593642702821-c8da6771f0c6?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=889&q=80",
    // ];

    const img1 = [bs1, bs2, bs3];
    const img2 = [eVab1, eVab2, eVab4, eVab5];
    const img3 = [eSuv4, eSuv1, eSuv2, eSuv3];
    const img4 = [ es2, es3];

    return (
    <section className="section">
      <Title title="fleet"/>

      <div className="carousel-center carousel">
        {/*{*/}
        {/*  Object.entries(fleets).map((fleet) => {*/}
        {/*    let imageArray = []*/}
        {/*     fleet[1].filter((image) => {*/}
        {/*      image.formats.medium && imageArray.push(image.formats.medium.childImageSharp.fluid.src)*/}
        {/*    })*/}
        {/*    return <Carousel images={imageArray} title={fleet[0]}/>*/}
        {/*    })*/}
        {/*}*/}
          <Carousel images={img1} title={"Business Sedan"}/>
          <Carousel images={img2} title={"Executive Van"}/>
          <Carousel images={img3} title={"Executive Suv"}/>
          <Carousel images={img4} title={"Executive Sedan"}/>

          {/*<Carousel images={img1} title={"Business Sedan"}/>*/}
          {/*<Carousel images={img2} title={"Executive Van"}/>*/}
          {/*<Carousel images={img3} title={"Executive Suv"}/>*/}
          {/*<Carousel images={img4} title={"Executive Sedan"}/>*/}

      </div>
        {/*<Link to="/fleet" className="btn center-btn">*/}
        {/*    View Our Fleet*/}
        {/*</Link>*/}
    </section>
  )
}

export default CarouselList
