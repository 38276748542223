import React, {useState} from "react";
import {Link, navigate} from "gatsby";
import styled from "styled-components";
import SocialLinks from "../constants/socialLinks";
import BookTransfer from "./BookingForm/BookTransfer/BookTransfer";
import {useNumericStepper} from "../hooks/useNumericStepper";
import moment from "moment";
import axios from "axios";
import {defaultOptions} from "../constants/toursDefaultValues";
import {REACT_APP_REST_RESOURCE_BASE_END_POINT} from "../constants/apiEndpoints";
import {roundUpTo} from "../utils/helperFunctions";
import {BsFillCaretRightFill} from "react-icons/bs";

//this is time interval used in time picker
const TIME_SELECT_INTERVAL = 5;

const Hero = () => {
    const [passengersCount, onPassengerAdd, onPassengerRemove] = useNumericStepper(1, 1);
    const [luggageCount, onLuggageAdd, onLuggageRemove] = useNumericStepper(1, 0);
    const [selectedDate, setSelectedDate] = useState(roundUpTo(TIME_SELECT_INTERVAL));
    const [origin, setOrigin] = useState({});
    const [destination, setDestination] = useState({});
    const [loading, setLoading] = useState(false);

    const onFromSelect = (e) => {
        //structure
        // e = {
        //     success: true/false,
        //     latLng: {
        //         lat: Number,
        //         lng: Number
        //     },
        //     error?: String
        // }
        setOrigin(e);
    };

    const onToSelect = (e) => {
        setDestination(e);
    };

    const onDateSelect = (e) => {
        setSelectedDate(e);
    };

    const onPickupTimeSelect = (e) => {
        setSelectedDate(e);
    };

    const onSubmit = () => {
        const {e: originFull, latLng: originCoordinates, success: originSuccess} = origin;
        const {e: destinationFull, latLng: destinationCoordinates, success: destinationSuccess} = destination;

        const transferDateTime = moment(selectedDate);
        const stripedDateTime = transferDateTime.format("YYYY-MM-DDTHH:mm:ss");

        let data = JSON.stringify({
            originCoordinates: originCoordinates,
            destinationCoordinates: destinationCoordinates,
            originData: originFull,
            destinationData: destinationFull,
            transferDateTime: stripedDateTime,
        });

        setLoading(true);

        axios.post(REACT_APP_REST_RESOURCE_BASE_END_POINT + `/prices`, data, {
                headers: {"Content-Type": "application/json"},
            })
            .then((r) => {
                const orderData = {
                    fetchedPrices: r.data,
                    passengersCount: passengersCount,
                    luggageCount: luggageCount,
                    fromData: origin,
                    toData: destination,
                    date: stripedDateTime,
                };

                localStorage.setItem("orderData", JSON.stringify(orderData));
                navigate("/vehicleList");
            })
            .catch((err) => {
                //display the error to the user
                alert(err);
                setLoading(false);
            });
    };

    return (
        <StyledHeader className="Wrapper">
            <StyledDiv className="InnerWrapper">
                <StyledArticle className="Main">
                    <div className="underline"/>
                    <h1>Private Travel</h1>
                    <h4>Transfers & Tours</h4>
                    <button onClick={() => window.$crisp.push(['do', 'chat:open'])} className="btn">Contact Us Now</button>
                    {/*<Link to="/contact" className="btn">*/}
                    {/*    contact us <BsFillCaretRightFill/>*/}
                    {/*</Link>*/}
                    {/*<SocialLinks/>*/}
                </StyledArticle>
            </StyledDiv>
            <StyledDiv className="BookTransferWrapper">
                <BookTransfer
                    defaultOptions={defaultOptions}
                    onFromOptionSelect={onFromSelect}
                    onToOptionSelect={onToSelect}
                    timeSelectInterval={TIME_SELECT_INTERVAL}
                    selectedDate={selectedDate}
                    onDateSelect={onDateSelect}
                    onPickupTimeSelect={onPickupTimeSelect}
                    luggageCount={luggageCount}
                    onLuggageAdd={onLuggageAdd}
                    onLuggageRemove={onLuggageRemove}
                    passengersCount={passengersCount}
                    onPassengerAdd={onPassengerAdd}
                    onPassengerRemove={onPassengerRemove}
                    onSubmit={onSubmit}
                    loading={loading}
                    disabledContinueButton={!origin.success || !destination.success}
                />
            </StyledDiv>
        </StyledHeader>
    );
};

const StyledHeader = styled.header`

  &.Wrapper {
    width: 70vw;
    min-height: 60vh;
    padding: 5rem 0;
    background-color: var(--clr-primary-10);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 1200px) {
    &.Wrapper {
      min-width: auto;
      width: 100%;
      padding: 10rem 0;
      flex-direction: column;
    }
  }
`;

const StyledDiv = styled.div`
  &.InnerWrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    transform: translateX(5rem);
  }

  &.BookTransferWrapper {
    transform: translateX(12.5rem);
  }

  @media (max-width: 1200px) {
    &.InnerWrapper {
      display: flex;
      flex-direction: column;
      transform: translateX(0);
    }

    &.BookTransferWrapper {
      margin-top: 5%;
      transform: translateX(0);
    }
  }
`;

const StyledArticle = styled.article`
  &.Main {
    .underline {
      margin-left: 0;
      margin-bottom: 0.25rem;
    }

    h1 {
      font-size: 4rem;
      margin-bottom: 0.75rem;
    }

    h4 {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      color: var(--clr-grey-4);
    }
  }

  @media (max-width: 1200px) {
    &.Main {
      h1 {
        font-size: 2rem;
      }
    }
  }
`;

export default Hero;
