import React, {useState, useEffect} from "react";
import "./style.css";
import {LeftArrow} from "./LeftArrow";
import {RightArrow} from "./RightArrow";

export const Carousel = ({images = [], title}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    
    const keydownHandler = ({ key }) => {
        switch (key) {
          case 'Escape':
            setModalOpen(false);
            break;
          default:
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    }, []);

    return (
        <>
            <div className="carousel-images">
                <div className="images">
                    <div className="title">{title}</div>
                    {images.map((item, index) => (
                        <img
                            src={item}
                            key={index}
                            alt={index}
                            className={activeIndex === index ? "show" : "hide"}
                            onClick={() => setModalOpen(true)}
                        />
                    ))}
                </div>
                <ul className="dots">
                    {images.map((_, index) => (
                        <li
                            key={index}
                            onMouseOver={() => setActiveIndex(index)}
                            className={index === activeIndex ? "active" : ""}
                        />
                    ))}
                </ul>
            </div>
            {isModalOpen && (
                <Modal onClose={() => setModalOpen(false)}>
                    <ModalImages images={images}/>
                </Modal>
            )}
        </>
    );
};

export const ModalImages = ({images = []}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleLeft = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };
    const handleRight = () => {
        if (activeIndex + 1 < images.length) {
            setActiveIndex(activeIndex + 1);
        }
    };
    return (
        <>
            <div className="images">
                {images.map((item, index) => (
                    <img
                        src={item}
                        key={index}
                        alt={index}
                        className={activeIndex === index ? "show" : "hide"}
                    />
                ))}
            </div>
            <ul className="dots">
                {images.map((_, index) => (
                    <li
                        key={index}
                        onMouseOver={() => setActiveIndex(index)}
                        className={index === activeIndex ? "active" : ""}
                    />
                ))}
            </ul>
            <div className="actions">
                <button onClick={handleLeft}>
                    <LeftArrow/>
                </button>
                <button onClick={handleRight}>
                    <RightArrow/>
                </button>
            </div>
        </>
    );
};

export const Modal = ({children, onClose = () => 0}) => (
    <div className="carousel-images-modal">
        <div className="layer" onClick={onClose}/>
        <div className="content">
            <div>
                <button onClick={onClose}>x</button>
                {children}
            </div>
        </div>
    </div>
);
