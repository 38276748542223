import React, { useState } from "react";
import classes from "./BookTransfer.module.css";
import Transfers from "./Transfers";
import Tabs from "../Tabs/Tabs";
import Tours from "./Tours";
import {Link, navigate} from "gatsby";

const BookTransfer = ({
    luggageCount,
    onLuggageAdd,
    onLuggageRemove,
    passengersCount,
    onPassengerAdd,
    onPassengerRemove,
    onSubmit,
    onDateSelect,
    onPickupTimeSelect,
    selectedDate,
    timeSelectInterval,
    onFromOptionSelect,
    onToOptionSelect,
    defaultOptions = [],
    loading,
    disabledContinueButton,
}) => {
    const [activeTab, setActiveTab] = useState("Transfers");
    return (
        <div className={classes.Wrapper}>
            <Tabs active={activeTab} options={["Transfers"]} //options={["Transfers", "Tours"]} onClick={setActiveTab}
            />
            {activeTab === "Transfers" ? (
                <Transfers
                    luggageCount={luggageCount}
                    onLuggageAdd={onLuggageAdd}
                    onLuggageRemove={onLuggageRemove}
                    passengersCount={passengersCount}
                    onPassengerAdd={onPassengerAdd}
                    onPassengerRemove={onPassengerRemove}
                    onSubmit={onSubmit}
                    onDateSelect={onDateSelect}
                    onPickupTimeSelect={onPickupTimeSelect}
                    selectedDate={selectedDate}
                    timeSelectInterval={timeSelectInterval}
                    onFromOptionSelect={onFromOptionSelect}
                    onToOptionSelect={onToOptionSelect}
                    defaultOptions={defaultOptions}
                    loading={loading}
                    disabledContinueButton={disabledContinueButton}
                />
            ) : (
                 <Tours />
                //navigate("/tours/")
                )}
        </div>
    );
};

export default BookTransfer;
