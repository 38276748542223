import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import SEO from "../components/SEO";
import Tours from "../components/Tours";
import Services from "../components/Services";
import Fleet from "../components/Fleet";
import Projects from "../components/Projects";
import Jobs from "../components/Jobs"
import Blogs from "../components/Blogs";
import CarouselList  from "../components/Carousel/CarouselList";
import {Carousel} from "../components/Carousel/Carousel";
import {Carousel2} from "../components/Carousel/Carousel2";


// git add . ; git commit -m "update"; git push

export default ({data}) => {
    const {
        allStrapiTours: {nodes: tours},
        allStrapiBlogs: {nodes: blogs},
        allStrapiProjects: {nodes: projects},
        allStrapiFleets: {nodes: fleets},
    } = data;

    debugger;
    return (
        <Layout>
            <SEO title="Home" description="this is our home page"/>
            <Hero/>
            <CarouselList />
            <Services/>
            {/*<Fleet/>*/}
            {/*<Tours tours={tours} title={"Tours"}/>*/}
            <Projects projects={projects} title="Projects" showLink/>
            {/*<Jobs/>*/}
            {/*<Blogs blogs={blogs} title="Popular Tours" showLink/>*/}
        </Layout>
    );
};

export const query = graphql`
    {
          allStrapiFleets {
        nodes {
          EconomyVan {
            id
            formats {
              medium {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }       
          ExecutiveSuv {
            id
            formats {
              medium {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }        
          ExecutiveVan {
            id
            formats {
              medium {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }                    
          BusinessSedan {
            id
            formats {
              medium {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
        allStrapiTours {
            nodes {
                id
                title
                type
                description
                image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        allStrapiProjects(filter: { featured: { eq: true } }) {
            nodes {
                id
                description
                title
                image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                locations {
                    id
                    title
                }
            }
        }
        allStrapiBlogs(sort: { fields: date, order: DESC }, limit: 3) {
            nodes {
                slug
                content
                description
                date(formatString: "MMMM Do, YYYY")
                id
                title
                category
                image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
