import React from "react";
import classes from "./BookTransfer.module.css";
import Title from "../../UI/Title/Title";
import Input from "../../UI/Input/Input";

const Tours = () => {
    return (
        <div className={classes.ToursWrapper}>
            <div className={classes.TitleWrapper}>
                <Title>Book a tour</Title>
            </div>
            <div className={classes.InputWrapper}>
                <Input />
            </div>
        </div>
    );
};

export default Tours;
