import React from "react"
import {FaCode, FaSketch, FaAndroid, FaFlickr} from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Private Transfers",
    text: `I'm baby meditation tofu chillwave, distillery messenger bag thundercats chicharrones kale chips gochujang. Banh mi direct trade marfa salvia.`,
  },
  {
    id: 2,
    icon: <FaFlickr className="service-icon" />,
    title: "Private Tours",
    text: `I'm baby meditation tofu chillwave, distillery messenger bag thundercats chicharrones kale chips gochujang. Banh mi direct trade marfa salvia.`,
  },
  {
    id: 3,
    icon: <FaSketch className="service-icon"/>,
    title: "Event Management",
    text: `Are you planning a weeding, conference, business party and you need to transport your guests, we are your solution.`,
  },
]
