import { useState } from "react";

export const useNumericStepper = (initialCount = 0, min = 0, max = 10) => {
    const [count, setCount] = useState(initialCount);

    const onIncrease = () => {
        setCount((e) => {
            if (e + 1 <= max) {
                return e + 1;
            }
            return e;
        });
    };

    const onDecrease = () => {
        setCount((e) => {
            if (e - 1 >= min) {
                return e - 1;
            }
            return e;
        });
    };

    return [count, onIncrease, onDecrease];
};
