import Sclass from "../assets/s-class-icon.jpg"
import Eclass from "../assets/e-class-icon.jpg"
import Vclass from "../assets/v-class-icon.jpg"
import Sprinter from "../assets/sprinter-icon.jpg"
import vianoEmbasy from '../assets/fleet/viano-embassy.jpg'

export default [
  {
    id: 1,
    image: Sclass,
    title: "S - Class",
  },
  {
    id: 2,
    image: Eclass,
    title: "E - Class",
  },
  {
    id: 3,
    image: Vclass,
    title: "V - Class",
  },
  {
    id: 4,
    image: Sprinter,
    title: "Sprinter",
  },
  {
    id: 5,
    image: Sprinter,
    title: "Economy",
  },
  {
    id: 6,
    image: vianoEmbasy,
    title: "Economy Van",
  },
]
